.wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media(min-width: 600px) {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-auto-flow: row dense;
  }
}

.modal-container {
  min-width: 70%;
  @media(max-width: 600px) {
    width: 90%;
  }

  overflow-y: auto !important;
}
