.root {
  height: 100%;
  color: black;
}

.loading {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  > * {
    margin-bottom: 8px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contentElementWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid black;
}

.adminModal {
}
.editFormDiv {
  display: flex;
  flex-direction: row;

  >input {
    flex-grow: 1  ;
    padding: 4px;
  }

}
